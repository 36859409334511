@import "bootstrap-icons";

// Font-Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

// Line Awesome
$la-font-path: "/assets/global/fonts/line-awesome";
@import "~line-awesome/dist/line-awesome/scss/line-awesome.scss";


// IziToast JS notifications extension
@import "~izitoast/dist/css/iziToast.min.css";